import styled from 'styled-components'
import React, { useEffect } from 'react'
import colors from '../../utils/style/colors'
import { useTheme, usePageTitleContext } from '../../utils/hooks'
import Test from '../../components/TestContainer'

import { Helmet, HelmetProvider } from 'react-helmet-async';



const PageWrapper = styled.main`
    margin: auto;
    max-width: 2400px;
    @media screen and (min-width: 2399px) {
        box-shadow: rgb(226, 227, 233) 2px -2px 6px;
    }
`

const MentionsSection = styled.section`
    margin: auto;
    padding: 20px 20px;
    align-items: center;
    background-color: ${({ theme }) => (theme === 'light' ? `${colors.backgroundLight}` : `${colors.backgroundLight}`)};
    transition: all 200ms;
    z-index: -1;
    max-width: 930px;
    text-align: center;
`


function Mentions() {
    const { theme } = useTheme()
    const { title, setTitle } = usePageTitleContext()
     
    
    // Set header page
    useEffect(() => {
        setTitle('Mentions légales');
    }, [title, setTitle]);

    // Set meta tags when Home Page loads
    const usePageMeta = (title, description) =>{
        const defaultTitle = "Mentions légales";
        const defaultDesc = "Voici les mentions légales de l'entreprise Becom'in, située à Bretteville-sur-Odon, près de Caen, en Normandie.";
    
        useEffect(() => {
            // Title meta tag
            document.title = title || defaultTitle;
            // Description meta tag
            document.querySelector("meta[name='description']").setAttribute("content", description || defaultDesc);
        }, [defaultTitle, title, defaultDesc, description]);
    };
    
    usePageMeta("Mentions légales", "Voici les mentions légales de l'entreprise Becom'in, située à Bretteville-sur-Odon, près de Caen, en Normandie.");
    

    return (
        <HelmetProvider>
            <PageWrapper id='main-content'>
                <Helmet>
                    <link rel="canonical" href="https://www.becom-in.fr/mentions-legales" />
                </Helmet>
                <MentionsSection theme={theme}>
                    <p>Le présent site internet est géré par la société BECOM'IN, nom commercial appartenant à la SARL OFFICE PRO SECRETARIAT, dont le siège social est situé à l'adresse suivante :<br/>
                        7, avenue de Glattbach<br/>
                        14760 BRETTEVILLE-SUR-ODON - France<br/>
                        <br/><br/>
                        L'entreprise est joignable par :<br/>   
                        <u>Téléphone</u> : 06 58 19 22 52 (numéro non surtaxé - prix d'un appel local)<br/>
                        <u>Email</u> : contact@officeprosecretariat.com<br/>
                        <br/>
                        OFFICE PRO SECRETARIAT - SARL au capital social de 1000 €.<br/><br/>
                        Entreprise immatriculée au R.C.S. de Caen - SIRET n° 882 500 630 000 18<br/>
                        Conditions générales de vente (CGV) disponibles en pied de page du site ou <a href='/conditions-generales-de-vente'>ici</a><br/>
                        <br/>
                        <u>Hébergeur du site Internet</u> : Hostinger<br/>
                        <br/>
                        Hostinger International Ltd.<br/>
                        61 Lordou Vironos Street<br/>
                        Larnaca 6023<br/>
                        Cyprus<br/>
                    </p>
                </MentionsSection>
                <Test></Test>
            </PageWrapper>
        </HelmetProvider>
    )
}

export default Mentions